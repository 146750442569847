.linktree {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.linktree-entry {
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: #3b3d42;
}

.linktree-entry:hover {
    background-color: #fe5186;
    border-color: #fe5186;
    color: #fff;
}

.linktree-inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    margin: auto;
}

.linktree-inner a {
    text-decoration: none;
    flex: 1;
    align-content: center;
    margin-right: 2.2rem;
}

.linktree-entry-icon {
    font-size: 1.7rem;
    margin-left: 0.5rem;
}