@mixin notice($light-accent, $dark-accent) {
    
    @media (prefers-color-scheme: dark) {
        border-left: 0.5rem solid rgba($dark-accent, 0.7);
        background: rgba($dark-accent, 0.2);
    }

    [data-theme=dark] & {
        border-left: 0.5rem solid rgba($dark-accent, 0.7);
        background: rgba($dark-accent, 0.2);
    }

    @media (prefers-color-scheme: light) {
        border-left: 0.5rem solid rgba($light-accent, 0.7);
        background: rgba($light-accent, 0.2);
    }

    [data-theme=light] & {
        border-left: 0.5rem solid rgba($light-accent, 0.7);
        background: rgba($light-accent, 0.2);
    }
    
    padding: 0.1rem;
    padding-left: 1rem;
    margin-bottom: 1rem;

    .title{
        font-size: large;
    }

    .content {
        align-items: start;
    }
}

.notice-info {
    @include notice($light-notice-info, $dark-notice-info);
}

.notice-tip {
    @include notice($light-notice-tip, $dark-notice-tip);
}

.notice-success {
    @include notice($light-notice-success, $dark-notice-success);
}

.notice-warning {
    @include notice($light-notice-warning, $dark-notice-warning);
}

.notice-danger {
    @include notice($light-notice-danger, $dark-notice-danger);
}

div.series-alert a {
    font-style: bold;
}